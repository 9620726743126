<template>
    <div>

        <div class="d-flex align-items-center justify-content-center" style="height: 90vh;">

          <div class="card">
            <qrcode-vue class="card-img-top" v-if="qr_code !== null" :value="qr_code" :size="500" ></qrcode-vue>
            <div class="card-body" style="text-align: center">
             <p>Please scan the above barcode using Google Authenticator</p>
             <input type="text" style="width: 12ch; margin-left: auto; margin-right: auto; text-align: center;" v-on:keyup.enter="auth" v-model="otp" maxlength="6" class="form-control">

              <p class="text-danger" v-if="error">Failed to authenticate.</p>

              <submit-button @click="auth" :background="'btn-info mt-2'" :text="'Authenticate'" :clicked="waiting" />
                
            </div>
          </div>
        </div>

    </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import axios from 'axios';
import SubmitButton from '../../../components/SubmitButton.vue';
export default {
    name: "GoogleTwoFactor",
    components: {
        SubmitButton,
        QrcodeVue
    },
    props: {
        route: String,
    },
    data(){
        return {
            secret: "",
            qr_code: null,
            otp: "",
            error: false,
            waiting: false,
        }
    },
    mounted() {
        axios.get(`${process.env.VUE_APP_API_URL}/v1/auth/2fa`)
        .then(response => {
          this.secret = response.data.base32;
            this.qr_code = `${response.data.otpauthUrl}`;
            console.log(this.qr_code);
        })
        .catch(() => {
            this.$error("Unable to generate a QR code at this time.");
        });
    },
    methods: {
        auth(){
            this.error = false;
            this.waiting = true;
            axios.post(`${process.env.VUE_APP_API_URL}/v1/auth/2fa`, {
                otp: this.otp,
                secret: this.secret
            })
            .then(() => {
                this.waiting = false;
                this.$success("Successfully validated your 2FA");
                if(this.route !== null && this.route !== undefined){
                    this.$router.push({name: this.route});
                }else{
                    this.$router.push({name: 'Dashboard'});
                }
            })
            .catch(error => {
                this.$error("2FA validation failed", error);
                console.error(error);
                this.error = true;
                this.waiting = false;
            });
        }
    }
}
</script>

<style scoped>

</style>
